.img-responsive {
    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
}

.swiper-slide.swiper-slide-next {
    /* transform: scale(1.3) !important;
    z-index: 2; */
}

.swiper-slide img {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 50px; */
}

.swiper-button-next,
.swiper-button-prev {
    color: white !important;
}

.person-hover {
    transition: all .2s ease-in-out;
}

.person-box-hover:hover {
    z-index: 10;
}

.person-hover:hover {
    transform: scale(1.5);
}